import React from 'react'

import { SwiperSlide } from 'swiper/react'
import BlockSlider from '../components/block-slider/block-slider'
import Layout from '../components/layout'
import ProofPoint from '../components/proof-point/proof-point'
import ScalingBanner from '../components/scaling-banner/scaling-banner'
import Seo from '../components/seo'
import SmallSpacer from '../components/spacer/small-spacer'
import Spacer from '../components/spacer/spacer'
import ImageAndContent from '../components/imageAndContent'
import OversetImage from '../components/overset-image/overset-image'
import SquareScalingBanner from '../components/square-scaling-banner/square-scaling-banner'
import FadeInView from '../components/fade-inview/fade-inview'
import ContentContainer from '../components/content-container/content-container'

import Marker from '../images/marker'
import { graphql } from 'gatsby'
import SubTitle from '../components/scaling-banner/sub-title'


function Svg() {
  return (
    <svg width="1126" height="303" viewBox="0 0 1126 303" fill="none" xmlns="http://www.w3.org/2000/svg">

      <path d="M110.31 0H0V302.812H110.31V257.391H45.4219V169.575H105.984V124.153H45.4219V45.4219H110.31V0Z" fill="#EEEAD9" />
      <path d="M266.738 130.784L233.107 261.569H272.343L289.905 176.746L307.841 261.569H347.077L313.446 130.784L346.329 0H307.094L289.905 84.8231L273.09 0H233.855L266.738 130.784Z" fill="#EEEAD9" />
      <path d="M568.634 36.5432C568.634 15.8883 552.746 0 532.091 0H479.66V222.437H513.025V142.995H532.091C554.335 142.995 568.634 128.696 568.634 106.452V36.5432ZM532.091 33.3655C533.998 33.3655 535.269 34.3188 535.269 36.5432V106.452C535.269 108.676 533.998 109.63 532.091 109.63H513.025V33.3655H532.091Z" fill="#EEEAD9" />
      <path d="M759.967 0H693.689V181.939H759.967V154.648H720.98V101.886H757.368V74.5952H720.98V27.2909H759.967V0Z" fill="#EEEAD9" />
      <path d="M953.939 24.4727C953.939 10.6403 943.299 0 929.466 0H894.354V148.964H916.698V92.5705H922.657L932.659 148.964H957.131L942.66 89.3784C949.683 85.3351 953.939 77.8869 953.939 68.0979V24.4727ZM929.466 22.3446C930.743 22.3446 931.595 22.983 931.595 24.4727V68.0979C931.595 69.5875 930.743 70.2259 929.466 70.2259H916.698V22.3446H929.466Z" fill="#EEEAD9" />
      <path d="M1070.2 0V18.3545H1088.56V122.363H1106.91V18.3545H1125.27V0H1070.2Z" fill="#EEEAD9" />
    </svg>
  )
}


const info = {
  heading: "AT SMITHFIELD, WE HAVE A LONG HISTORY OF INNOVATION FROM STEAM FLAKING TO X WE’RE CONSTANTLY PUSHING OUR TEAM AND INDUSTRY FORWARD. TEMPOR INCIDIDUNT UT LABORE ET DOLORE MAGNA ALIQUA.",
  paragraphs: [
    {
      name: '',
      value: 'AT SMITHFIELD, WE HAVE A LONG HISTORY OF INNOVATION FROM STEAM FLAKING TO X WE’RE CONSTANTLY PUSHING OUR TEAM AND INDUSTRY FORWARD. TEMPOR INCIDIDUNT UT LABORE ET DOLORE MAGNA ALIQUA. AT SMITHFIELD, WE HAVE A LONG HISTORY OF INNOVATION FROM STEAM FLAKING TO X WE’RE CONSTANTLY PUSHING OUR TEAM AND INDUSTRY FORWARD. TEMPOR INCIDIDUNT UT LABORE ET DOLORE MAGNA ALIQUA. AT SMITHFIELD, WE HAVE A LONG HISTORY OF INNOVATION FROM STEAM FLAKING TO X WE’RE CONSTANTLY PUSHING OUR TEAM AND INDUSTRY FORWARD. TEMPOR INCIDIDUNT UT LABORE ET DOLORE MAGNA ALIQUA.'
    },

  ]
}
export default function Expertise({ data }) {
  const { expertise, teamPage } = data

  return (
    <Layout>
      <Seo title='Expertise' />
      <ScalingBanner video={expertise.videoLink} image={expertise.heroImage} svg={<Svg />} title='Expertise' >
        <div className='flex flex-end'>
          <SubTitle title='lot feeders' />
        </div>
      </ScalingBanner>
      <SmallSpacer />
      <Spacer />
      <ContentContainer className='text-center flex ju-c'>
        <h3 className='max-400 ma '>{expertise.inovationDescription[0].title}</h3>
        <SmallSpacer />
        <SmallSpacer />
        <h4 className='large-sentence light-white max-800 ma suede '>{expertise.inovationDescription[0].subtitle}</h4>
        <SmallSpacer />
        <p className='body-copy max-400 ma suede'>{expertise.inovationDescription[0].paragraph}</p>
      </ContentContainer>
      <Spacer />
      <BlockSlider id='5' className='proof--slider'>
        {expertise.innovationProofPoint.map((point) => {
          return <SwiperSlide className='max-400'>

            <ProofPoint proofPoint={point} />
          </SwiperSlide>
        })}
      </BlockSlider>

      <SmallSpacer />
      <Spacer />

      <ImageAndContent image={expertise.insetImageLivestock} title={expertise.livestockDescription[0].title} subtitle={expertise.livestockDescription[0].subtitle} content={expertise.livestockDescription[0].paragraph} />

      <ImageAndContent image={expertise.insetImageProcurement} title={expertise.procurementDescription[0].title} subtitle={expertise.procurementDescription[0].subtitle} content={expertise.procurementDescription[0].paragraph} />

      <ImageAndContent image={expertise.insetImageMilling} title={expertise.millingDescription[0].title} subtitle={expertise.millingDescription[0].subtitle} content={expertise.millingDescription[0].paragraph} />

      <Spacer className='bt1 m-h-30' />
      <FadeInView>
        <Spacer className='m-hide' />
        <ContentContainer>
          <div className='flex ju-c flex-c al-i-c    '>
            <h3 className='max-325 text-center'>{expertise.aLotOfCare}</h3>
            <SmallSpacer />
            <p className='small-serif max-600 suede text-center  '>{expertise.aLotOfCareSubtitle} </p>
          </div>
        </ContentContainer>
      </FadeInView>

      <Spacer />
      <FadeInView>
        <BlockSlider id='1' className='proof--slider' >
          {expertise.aLotOfCareProofPoint.map((point) => {
            return (
              <SwiperSlide className='max-400'>
                <ProofPoint proofPoint={point} />
              </SwiperSlide>
            )
          })}
        </BlockSlider>
      </FadeInView>
      <Spacer />
      <Spacer className='m-hide' />
      <OversetImage image={expertise.goingGreenImage} title={expertise.goingGreen} background={expertise.goingGreenBackground} />
      <Spacer />
      <ContentContainer>
        <div className='flex ju-c flex-c al-i-c'>
          <h3 className='max-500 text-center'>{expertise.sustainingAndRegenerating}</h3>
          <SmallSpacer />
          <p className='small-serif max-600 suede text-center'>{expertise.sustainingAndRegeneratingSubtitle} </p>
        </div>
      </ContentContainer>
      <Spacer />
      <FadeInView>
        <BlockSlider id='2' className='proof--slider' >
          {expertise.sustainingProofPoint.map((point) => {
            return <SwiperSlide className='max-400'>
              <ProofPoint proofPoint={point} />
            </SwiperSlide>
          })}
        </BlockSlider>
      </FadeInView>
      <Spacer />
      <SquareScalingBanner image={teamPage.heroImage} link='/team'>
        <h3 className='small-serif'>Next Page</h3>
        <SmallSpacer />
        <h2 className='h1 m-h3'>Team</h2>
      </SquareScalingBanner>
      <Spacer />





    </Layout>
  )
}


export const query = graphql`
query ExpertiseQuery {  
    teamPage:datoCmsTeamPage {
        heroImage {
        gatsbyImageData
        }
    }
    expertise:datoCmsExpertise {
        sustainingAndRegenerating
        sustainingProofPoint {
      description
      image {
        gatsbyImageData
      }
      name
    }
    goingGreenImage {
      gatsbyImageData
    }
    goingGreenBackground {
      gatsbyImageData
    }
    goingGreen

   
    sustainingAndRegeneratingSubtitle
    aLotOfCare
    aLotOfCareProofPoint {
      name
      description
      image {
        gatsbyImageData
      }
    }
    aLotOfCareSubtitle
        millingDescription {
      title
      subtitle
      paragraph
    }
        procurementDescription {
      title
      subtitle
      paragraph


    }
        inovationDescription {

      title
      subtitle
      paragraph
    }
    insetImageMilling {
      gatsbyImageData
    }
    insetImageLivestock {
      gatsbyImageData
    }

    livestockDescription {
      title
      subtitle
      paragraph  
    }
        insetImageProcurement {
      gatsbyImageData
    }
        heroImage {
            url
            gatsbyImageData
        }
        videoLink
        innovationProofPoint {
      name
      description
      image {
        gatsbyImageData
      }
    }
    }
}
`

